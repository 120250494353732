/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo } from "react";
import { useAceAccountManagement } from "../ace-account-management-provider";
import { getEnv } from "@ace/env";

const gateway = getEnv("VITE_ACE_WEB_AUTH_SERVER");

const fetchParsedResponse = async (uri: string, options: any) => {
  const response = await fetch(uri, options);
  return await response.json();
};

type FetchGatewayParams = [
  jwt: string | undefined,
  scriptPath: string,
  options?: any,
];
type FetchGateway<T = any> = (...params: FetchGatewayParams) => Promise<T>;

export const fetchGateway: FetchGateway = async (jwt, scriptPath, options) => {
  const { method = "GET", headers, body } = options ?? {};
  const url = `${gateway}${scriptPath}`;
  const response = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${jwt}`,
      ...headers,
    },
    body,
  });
  if (response.status !== 200) console.error(response);
  return response;
};

export const fetchGatewayBlob: FetchGateway = (...params) =>
  fetchGateway(...params).then((response) => response.blob());

export const openFileBlob: FetchGateway = async (...params) => {
  const blob = await fetchGatewayBlob(...params);
  window.open(URL.createObjectURL(blob), "_blank");
  return Promise.resolve();
};

export const fetchGatewayJson: FetchGateway = (...params) =>
  fetchGateway(...params).then((response) => response.json());

/**
 * The browser native `fetch` but with the ace jwt token appended.
 * Can be used only within the `AceAccountManagementProvider`
 */
export const useFetch = (gateway: string) => {
  const { currentToken } = useAceAccountManagement();

  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentToken}`,
    }),
    [currentToken],
  );

  const fetchGet = useCallback(
    (path: string) =>
      fetchParsedResponse(`${gateway}${path}`, {
        method: "GET",
        headers,
      }),
    [headers],
  );

  const fetchPost = useCallback(
    (path: string, payload: any) =>
      fetchParsedResponse(`${gateway}${path}`, {
        method: "POST",
        headers,
        body: JSON.stringify(payload),
      }),
    [headers],
  );

  const fetchPut = useCallback(
    (path: string, payload: any) =>
      fetchParsedResponse(`${gateway}${path}`, {
        method: "PUT",
        headers,
        body: JSON.stringify(payload),
      }),
    [headers],
  );

  const fetchDel = useCallback(
    (path: string, payload: any) =>
      fetchParsedResponse(`${gateway}${path}`, {
        method: "DEL",
        headers,
        body: JSON.stringify(payload),
      }),
    [headers],
  );

  return { fetchGet, fetchDel, fetchPost, fetchPut };
};
