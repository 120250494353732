import { getEnv } from "@ace/env";

export type SignedContract = {
  contractId: number | string;
  dateAccepted: string;
  signingHash: string;
};

export const signContract = async (
  token: string,
  contractKey: string,
): Promise<SignedContract> => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const response = await fetch(
    `${getEnv("VITE_ACE_WEB_AUTH_SERVER")}/contracts/sign-key/${contractKey}`,
    {
      method: "POST",
      headers,
    },
  );
  const json = await response.json();

  if (json.status !== "OK") {
    throw new Error(json.error[0].msg);
  }

  const data = json.data as SignedContract;

  return data;
};
