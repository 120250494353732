import { useContext, createContext, useState, useEffect, useRef } from "react";
import { expiresWithInXMinutes, isTokenExpired } from "./jwt";
import { REASONS_TO_END_SESSION } from "./constants";
import { KycData } from "./types/ace-types";

const DATA_POLL_INTERVAL = 30000;

const AceAccountManagementContext = createContext<{
  profileData: any;
  kycData: KycData | null;
  loading: boolean;
  currentToken: any;
  triggerDataUpdate: () => void;
}>({
  profileData: null,
  kycData: null,
  loading: false,
  currentToken: "",
  triggerDataUpdate: () => {},
});

interface AceAccountManagementProviderProps {
  token: string;
  autoRenewalStorageKey: string;
  aceAuthRestUrl: string;
  onError?: (error: string) => void;
  children: React.ReactNode;
}

/**
 * The context provider is built to be used with `useAceAccountManagement` - requires a JWT and the ace-auth url (should be passed in the applications .env)
 *
 * @param {string} props.token - The token retrieved from the ACE AUTH Rest endpoint.
 * @param {string=} props.autoRenewalStorageKey - Optional: if given a storage key, this provider will make the renewal calls to ensure that localstorage entry has a valid token.
 * @param {string} props.aceAuthRestUrl - The url for the ace auth REST endpoint.
 * @param {string} props.onError - A callback triggered when a failure occurs.
 *
 */

const AceAccountManagementProvider = (
  props: AceAccountManagementProviderProps,
) => {
  const [profileData, setProfileData] = useState<any | null>(null);
  const [kycData, setKycData] = useState<any | null>(null);
  const tokenRef = useRef(props.token);
  const pageVisible = useRef(true);

  const fetchUserProfile = () => {
    fetch(`${props.aceAuthRestUrl}/profile`, {
      headers: {
        Authorization: `Bearer ${tokenRef.current}`,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === "ERROR") {
          const error = res.error[0].msg;
          if (REASONS_TO_END_SESSION.includes(error)) {
            props.onError && props.onError(error);
            throw new Error(error);
          }
        }
        setProfileData(res.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const fetcUserKyc = () => {
    fetch(`${props.aceAuthRestUrl}/kyc`, {
      headers: {
        Authorization: `Bearer ${tokenRef.current}`,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === "ERROR") {
          const error = res.error[0].msg;
          if (REASONS_TO_END_SESSION.includes(error)) {
            props.onError && props.onError(error);
            throw new Error(error);
          }
        }
        setKycData(res.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const renewToken = () => {
    fetch(`${props.aceAuthRestUrl}/renew-jwt`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenRef.current}`,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === "ERROR") {
          const error = res.error[0].msg;
          throw new Error(error);
        }
        tokenRef.current = res.data.jwt;
        window.sessionStorage.setItem(
          props.autoRenewalStorageKey,
          tokenRef.current,
        );
        window.dispatchEvent(
          new StorageEvent("storage", {
            key: props.autoRenewalStorageKey,
            oldValue: null,
            newValue: tokenRef.current,
            url: window.location.href,
            storageArea: sessionStorage,
          }),
        );
      })
      .catch((e) => {
        props.onError && props.onError(e);
      });
  };

  // These need to be in the one function cause calling for data close to or during renewing can causes JWT errors.
  const seUpDataAndRenewInterval = () => {
    fetcUserKyc();
    fetchUserProfile();

    return setInterval(() => {
      if (expiresWithInXMinutes(tokenRef.current, 10)) {
        renewToken();
        return;
      }

      if (pageVisible.current) {
        fetcUserKyc();
        fetchUserProfile();
        return;
      }
    }, DATA_POLL_INTERVAL);
  };

  const triggerDataUpdate = () => {
    setProfileData(null);
    setKycData(null);
    fetcUserKyc();
    fetchUserProfile();
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      pageVisible.current = true;
      if (isTokenExpired(tokenRef.current)) {
        props.onError && props.onError("JWT expired");
      }
    }
    if (document.visibilityState === "hidden") {
      pageVisible.current = false;
    }
  };

  useEffect(() => {
    if (isTokenExpired(props.token)) {
      props.onError && props.onError("JWT Expired");
    }

    const dataAndRenewHandle = seUpDataAndRenewInterval();

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(dataAndRenewHandle);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AceAccountManagementContext.Provider
      value={{
        profileData,
        kycData,
        loading: !(profileData && kycData),
        currentToken: tokenRef.current,
        triggerDataUpdate,
      }}
    >
      {props.children}
    </AceAccountManagementContext.Provider>
  );
};

/**
 *
 * This react hook maintains up-to-date profile, kyc and other information a UI would need to support Ace Account Management functionality.
 *
 * Any alterations made to profile either manually or REST calls in other parts of this app. Should quickly (long-polling vs real-time) reflect in the data provided by this hook.
 *
 * ```
 * const { profileData, kycData, loading, currentToken, triggerDataUpdate } = useAceAccountManagement();
 * ```
 *
 * To be used with `<AceAccountManagementProvider />` at the root of your application.
 *
 */
const useAceAccountManagement = () => {
  const { profileData, kycData, loading, currentToken, triggerDataUpdate } =
    useContext(AceAccountManagementContext);

  return {
    profileData,
    kycData,
    loading,
    currentToken,
    triggerDataUpdate,
  };
};

export { AceAccountManagementProvider, useAceAccountManagement };
