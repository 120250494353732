export const paths = {
  blank: "/blank",

  tradeDashboard: "/dashboard",
  simplifiedView: "/simplified-view",
  portfolio: "/dashboard/portfolio",
  transactions: "/dashboard/transactions",
  fundingStatus: "/dashboard/fundingstatus",

  account: "/account",
  accountOverview: "/account/overview",
  amlkyc: "/account/amlkyc",
  finPromCategorisation: "/account/finPromCategorisation",
  investorCategorisation: "/account/investorCategorisation",
  regulatedContracts: "/account/regulated-contracts",
  unregulatedContracts: "/account/unregulated-contracts",
  documentLibrary: "/account/documentLibrary",
  appropriatenessTest: "/account/appropriateness-test",
  clientCategorisation: "/account/client-categorisation",
  restrictedInvestor: "/account/restricted-investor",

  // ONBOARDING ROUTES
  countrySelection: "/account/select-country",
  accountTypeSelection: "/account/select-account-type",

  // UNAUTHORIZED ROUTES
  index: "/",
  login: "/",
  logout: "/?logout=true",
  setupMfa: "/setup-mfa",
  register: "/register",
  confirmRegistration: "/register/confirm/:confirmation",
  resetPassword: "/reset-password",
  setPassword: "/set-password/:confirmation",
};
