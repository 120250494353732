import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useMediaQuery, Theme } from "@mui/system";

const RISK_HEADER_HEIGHT = 32;

export const RiskHeader = () => {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  return (
    <Box
      component="div"
      sx={{
        backgroundColor: "#B2D4EA",
        color: "black",
        position: "fixed",
        width: "100%",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      id="risk-header"
      data-cy="risk-header"
    >
      <Typography
        textAlign={{ xs: "left", md: "center" }}
        mx={{ xs: 3, md: 2 }}
        my={smUp ? 0 : 1}
        variant="subtitle2"
        sx={{
          "& a": {
            color: "black",
          },
          lineHeight: smUp ? `${RISK_HEADER_HEIGHT}px` : `15px`,
          fontSize: smUp ? null : "12px",
        }}
      >
        Don't invest unless you're prepared to lose all the money you invest.
        This is a high-risk investment and you should not expect to be protected
        if something goes wrong.{" "}
        <a target="#" href="https://archax.com/uk-risk-warning">
          Take 2 minutes to learn more
        </a>
      </Typography>
    </Box>
  );
};
