import { debounce } from "lodash";
import { getExpiration, isTokenExpired } from "./jwt";
import {
  AceConnectionStatus,
  AceIncomingSocketMessage,
} from "./types/ace-types";
import { REASONS_TO_END_SESSION } from "./constants";

// This was added to avoid the websocket trying to reconnect if the client was the one to kill it i.e. socket.close();
// This fixes the hot-reload/vite case.
const CLIENT_SIDE_CLOSURE = "client-side-closure";
const TIMEOUT_THRESHOLD = 3000;
const IDLE_TIME_BEFORE_FORCE_RECONNECT = 60000;
const RECONNECTION_ATTEMPTS_TIMEOUT = 30000;
let currentIdleTime: number | null = null;

interface AceWebsocketParams {
  url: string;
  token: string;
  updateConnectionStatus: (status: AceConnectionStatus) => void;
  updateToken: (token: string) => void;
  autoRenewalStorageKey: string;
  onError?: (message: string, error?: any) => void;
  onAuthenticated?: (socket: WebSocket) => void;
  onData: (action: string, data: any) => void;
}

const scheduleRenewal = (token: string, socket?: WebSocket) => {
  const exp = getExpiration(token);
  const renewalTime = (exp - 300) * 1000;
  const systemTime = new Date().getTime();
  const renewalTimeoutMillis = renewalTime - systemTime;

  return setTimeout(
    () => {
      socket?.send(JSON.stringify({ action: "renew-jwt" }));
    },
    renewalTimeoutMillis,
    token,
  );
};

const AceWebsocket = ({
  url,
  token = "",
  updateConnectionStatus,
  updateToken,
  autoRenewalStorageKey,
  onError = () => {},
  onAuthenticated = () => {},
  onData,
}: AceWebsocketParams): {
  socket: WebSocket;
  handleSocketError: (message: string, e?: Error) => void; // only for testing
  cleanUpAceSocket: (reason?: string) => void;
} => {
  let socket: any = new WebSocket(`${url}/gateway`);
  // Need to add error handler immediately to check if the url itself fails.
  socket.addEventListener("error", (e: Event) => {
    onError(`Error: ${e.type}, Target: ${JSON.stringify(e.target)}`);
    console.log(e);
  });

  let currentToken = token;
  let renewalHandle: number | null;
  let pingIntervalHandle: number | null;
  let socketConnectionStatus: AceConnectionStatus;

  if (!token) {
    console.error("No token has been provided to AceConnectionProvider");
  }

  const handleConnectionStatusUpdate = (status: AceConnectionStatus) => {
    socketConnectionStatus = status;
    updateConnectionStatus(socketConnectionStatus);
  };

  const handleTokenUpdate = (newToken: string) => {
    currentToken = newToken;
    updateToken(currentToken);
  };

  const handleSocketError = (message: string, e?: Error) => {
    cleanUpAceSocket();
    onError(message, e);
  };

  /**
   *  If we stop getting ping/pongs and to mitigate any state/sync risk (missed ws messages),
   *  It's safest to fail the connection completely, and attempt to re-connect to pull fresh snapshots.
   *  */
  const heartBeatFailureTimeout = debounce(() => {
    console.debug(
      "ACE Websocket: connection timeout out waiting for heartbeat, closing connection and re-open",
      new Date().toISOString(),
    );

    // we can't simply close() the socket, close event doesn't fire.
    // we must bin and re-create.
    cleanUpAceSocket("reconnect");
  }, TIMEOUT_THRESHOLD);

  const setUpIntervalTimeout = () => {
    return window.setInterval(() => {
      if (socket.readyState === socket.OPEN) {
        socket.send("ping");
      }
    }, TIMEOUT_THRESHOLD / 2);
  };

  const cleanUpAceSocket = (reason?: string) => {
    renewalHandle && clearTimeout(renewalHandle);
    renewalHandle = null;
    pingIntervalHandle && clearInterval(pingIntervalHandle);
    pingIntervalHandle = null;
    heartBeatFailureTimeout.cancel();
    document.removeEventListener("visibilitychange", handleVisibilityChange);
    socket?.close(1000, reason || CLIENT_SIDE_CLOSURE);
    socket = null;

    if (reason === "reconnect" || reason === "clean-state") {
      if (!isTokenExpired(currentToken)) {
        setTimeout(() => {
          startReconnectAttempts();
        }, 250);
      }
    }
  };

  // Single attempt to reconnect and replace current socket instance.
  const reconnect = ({
    scheduleInMs,
  }: {
    scheduleInMs: number;
  }): Promise<boolean> => {
    return new Promise((resolve) =>
      setTimeout(() => {
        let newSocket: WebSocket | null;
        let socketOpenTimeout: number | null = null;

        const cleanup = () => {
          if (socketOpenTimeout) {
            clearTimeout(socketOpenTimeout);
          }
          if (newSocket) {
            newSocket.onopen = null;
            newSocket.onerror = null;
            newSocket.onclose = null;
            newSocket.close();
            newSocket = null;
          }
        };

        try {
          newSocket = new WebSocket(`${url}/gateway`);
          newSocket.onopen = () => {
            if (socketOpenTimeout) {
              clearTimeout(socketOpenTimeout);
            }
            console.debug("Reconnected successfully");
            socket = newSocket;
            socket.onopen = null;
            socket.onerror = null;
            onOpen();
            resolve(true);
          };

          newSocket.onerror = () => {
            cleanup();
            console.debug("Reconnect attempt failed");
            resolve(false);
          };

          // If the socket takes too long to open, reject the attempt
          socketOpenTimeout = Number(
            setTimeout(() => {
              cleanup();
              console.debug("Reconnect attempt timed out");
              resolve(false);
            }, 3000),
          );
        } catch (e) {
          cleanup();
          console.error("Failed to create new socket", e);
          resolve(false);
        }
      }, scheduleInMs),
    );
  };

  const startReconnectAttempts = async () => {
    if (
      socketConnectionStatus !== AceConnectionStatus.INITIALISING &&
      socketConnectionStatus !== AceConnectionStatus.RECONNECTING
    ) {
      handleConnectionStatusUpdate(AceConnectionStatus.RECONNECTING);
      let backoff = 500;
      const timeoutLimit = Date.now() + RECONNECTION_ATTEMPTS_TIMEOUT;

      while (Date.now() < timeoutLimit) {
        backoff *= 2;
        console.log(`Attempt reconnect in ${backoff}ms`);
        const success = await reconnect({ scheduleInMs: backoff });
        if (success) {
          return;
        }
      }

      console.log("Max reconnection timeout reached");
      handleSocketError("Failed to reconnect");
      return;
    }
  };

  const onOpen = () => {
    socket.send(
      JSON.stringify({
        action: "login",
        service: "core",
        token: currentToken,
      }),
    );

    socket.addEventListener("message", (message: any) => {
      if (message.data === "pong") {
        if (
          socketConnectionStatus !== AceConnectionStatus.CONNECTED &&
          socket.readyState === socket.OPEN
        ) {
          handleConnectionStatusUpdate(AceConnectionStatus.CONNECTED);
        }
        heartBeatFailureTimeout();
        return;
      }

      const { action, data, status, reason } = JSON.parse(
        message.data,
      ) as AceIncomingSocketMessage;

      if (status === "error") {
        console.log(reason);
        if (reason && REASONS_TO_END_SESSION.includes(reason)) {
          handleConnectionStatusUpdate(AceConnectionStatus.FAILED);
          heartBeatFailureTimeout.cancel();
          handleSocketError(reason);
          return;
        }
      }

      if (action === "renew-jwt-response") {
        if (status === "ERROR") {
          cleanUpAceSocket();
          handleSocketError("Renewal failed");
          return;
        }
        console.debug("ACE Websocket: Renewel Successful");
        handleTokenUpdate(data.jwt);
        if (autoRenewalStorageKey) {
          window.sessionStorage.setItem(autoRenewalStorageKey, data.jwt);
          window.dispatchEvent(
            new StorageEvent("storage", {
              key: autoRenewalStorageKey,
              oldValue: null,
              newValue: data.jwt,
              url: window.location.href,
              storageArea: sessionStorage,
            }),
          );
        }
        renewalHandle = scheduleRenewal(data.jwt, socket);
        return;
      }

      // Post login setup
      if (action === "user-login-success") {
        console.debug(
          `[${new Date().toISOString()}] ACE Websocket: Login Successful`,
        );
        if (!pingIntervalHandle) {
          pingIntervalHandle = setUpIntervalTimeout();
        }
        renewalHandle = scheduleRenewal(currentToken, socket);
        handleConnectionStatusUpdate(AceConnectionStatus.CONNECTED);
        onAuthenticated(socket);
        return;
      }

      // Handle incoming data
      if (action?.includes("set-") || action?.includes("append-")) {
        const prefix = action.includes("set-") ? "set-" : "append-";
        const dataTopic = action.split(prefix)[1];
        onData(dataTopic, data);
        return;
      }
    });

    socket.addEventListener("error", (e: Event) => {
      handleSocketError(
        `Error: ${e.type}, Target: ${JSON.stringify(e.target)}`,
      );
      console.log(e);
    });

    // ws close events are non-intuitive.
    // example: close event isn't fired when device gets disconnected from internet.
    socket.addEventListener("close", (e: any) => {
      console.debug(
        `ACE Websocket: Connection closed....${new Date().toLocaleTimeString()}`,
        e,
      );
      if (e.code === 1000) {
        cleanUpAceSocket();
        return;
      }
      if (e.code === 1011) {
        handleSocketError("Socket closed with 1011: Server Error", e);
        return;
      }
      if (e.code === 1006) {
        handleSocketError("Connection closed without frame from server", e);
        return;
      }
    });

    document.addEventListener("visibilitychange", handleVisibilityChange);
  };

  socket.addEventListener("open", onOpen);

  // need to check the jwt/connection when the user comes back to the tab/un-idles.
  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      if (isTokenExpired(currentToken)) {
        cleanUpAceSocket();
        handleSocketError("JWT expired");
        return;
      }

      if (socket.readyState === socket.CLOSED) {
        cleanUpAceSocket();
        handleSocketError("socket closed while idle");
        return;
      }

      // When the browser is idle it can throttle socket/timeouts/intervals
      // To ensure UI doesn't go stale, if they have been away for some constant time,
      // force a reconnect for a clean state and connection.
      if (
        currentIdleTime &&
        Date.now() - currentIdleTime > IDLE_TIME_BEFORE_FORCE_RECONNECT
      ) {
        cleanUpAceSocket("reconnect");
      }
    } else {
      currentIdleTime = Date.now();
    }
  };

  return {
    socket,
    handleSocketError, // for testing
    cleanUpAceSocket,
  };
};

export { AceWebsocket };
