import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";

interface AnalyticsBannerProps {
  onAccepted: () => void;
  onRejected?: () => void;
}

const AnalyticsBanner = ({ onAccepted, onRejected }: AnalyticsBannerProps) => {
  return (
    <Dialog
      open={true}
      onClose={onRejected}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ mt: 3 }}>
        {"We've Updated our Privacy and Cookies Policy"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          We’ve made changes to our{" "}
          <Link
            href="https://archax.com/privacy-policies"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          , and we encourage you to review them. By continuing to use our
          services, you agree to the new Terms of Service and acknowledge the
          Privacy Policy applies to you.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onAccepted} color="primary">
          I Accept
        </Button>
        {/* <Button variant="contained" onClick={onRejected} color="primary">
          Decline
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export { AnalyticsBanner };
