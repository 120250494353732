// @ts-nocheck
/**
 * Get the value from the environment
 *
 * @param name
 * @param defaultValue
 */

type EnvWindow = Window & { _env_?: any };

const getEnv = (name = "", defaultValue = "") => {
  let envVars = null;

  if ((window as EnvWindow)._env_) {
    envVars = (window as EnvWindow)._env_; // this is what will resolve at runtime.
  } else if (typeof import.meta !== "undefined" && import.meta.env) {
    envVars = import.meta.env; // This is for local development with Vite.
  } else {
    envVars = process.env; // This is for local development with create-react-app
  }

  return name ? envVars[name] || defaultValue : envVars;
};

export { getEnv };
