import { Button, Card, Stack, Typography } from "@mui/material";

interface UnregulatedMasterAgreementPopup {
  onLeave: () => void;
  onConfirm: () => void;
}

export const UnregulatedMasterAgreementPopup = ({
  onLeave,
  onConfirm,
}: UnregulatedMasterAgreementPopup) => {
  return (
    <Card
      sx={{ p: 3, maxWidth: "542px" }}
      data-cy="unregulated-master-agreement-popup"
    >
      <Stack spacing={3} alignItems="center" textAlign="center">
        <img
          src="/public/assets/success.svg"
          alt="success icon"
          height="110px"
          style={{ margin: 8 }}
        />
        <Stack spacing={2}>
          <Typography variant="h5">
            Crypto and other unregulated products master agreement
          </Typography>
          <Typography variant="body2">
            I confirm that I have read and understood the contents of this
            document.
          </Typography>
        </Stack>
        <Stack
          direction={{ sm: "column", md: "row" }}
          justifyContent="center"
          gap={3}
          width="100%"
        >
          <Button
            variant="contained"
            onClick={onLeave}
            data-cy="unregulated-master-agreement-popup-back-button"
          >
            Back to account
          </Button>
          <Button
            variant="contained"
            onClick={onConfirm}
            data-cy="unregulated-master-agreement-popup-confirm-button"
          >
            I confirm
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};
