import { FC } from "react";
import { useMemo } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

import { Scrollbar } from "../../../components/scrollbar";
import { usePathname } from "../../../hooks/use-pathname";
import { useRouter } from "../../../hooks/use-router";
import { useRiskBannerHeight } from "../../../hooks/ace/use-risk-banner-height";
import { paths } from "../../../paths";
import type { NavColor } from "../../../types/settings";

import { footerSection, type Section } from "../config";
import { SideNavSection } from "./side-nav-section";
import { Logout } from "../../../svgs/logout";
import { ButtonBase, Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const SIDE_NAV_WIDTH = 235;
export const SIDE_NAV_WIDTH_CLOSED = 20;

const useCssVars = (color: NavColor): Record<string, string> => {
  const theme = useTheme();

  return useMemo((): Record<string, string> => {
    switch (color) {
      case "blend-in":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.neutral[100],
            "--nav-border-color": theme.palette.neutral[700],
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[600],
            "--nav-item-icon-color": theme.palette.neutral[500],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[700],
            "--nav-item-chevron-color": theme.palette.neutral[700],
            "--nav-scrollbar-color": theme.palette.neutral[400],
            "--nav-collapse-hover": theme.palette.neutral[800],
          };
        } else {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.text.primary,
            "--nav-border-color": theme.palette.neutral[100],
            "--nav-logo-border": theme.palette.neutral[100],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.text.secondary,
            "--nav-item-hover-bg": theme.palette.action.hover,
            "--nav-item-active-bg": theme.palette.action.selected,
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[400],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[400],
            "--nav-item-chevron-color": theme.palette.neutral[400],
            "--nav-scrollbar-color": theme.palette.neutral[900],
            "--nav-collapse-hover": theme.palette.neutral[200],
          };
        }

      case "discrete":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.neutral[900],
            "--nav-color": theme.palette.neutral[100],
            "--nav-border-color": theme.palette.neutral[700],
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.text.primary,
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[600],
            "--nav-item-icon-color": theme.palette.neutral[500],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[700],
            "--nav-item-chevron-color": theme.palette.neutral[500],
            "--nav-scrollbar-color": theme.palette.neutral[400],
            "--nav-collapse-hover": theme.palette.neutral[800],
          };
        } else {
          return {
            "--nav-bg": theme.palette.neutral[50],
            "--nav-color": theme.palette.text.primary,
            "--nav-border-color": theme.palette.divider,
            "--nav-logo-border": theme.palette.neutral[200],
            "--nav-section-title-color": theme.palette.neutral[500],
            "--nav-item-color": theme.palette.neutral[500],
            "--nav-item-hover-bg": theme.palette.action.hover,
            "--nav-item-active-bg": theme.palette.action.selected,
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[400],
            "--nav-item-icon-color": theme.palette.neutral[600],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[400],
            "--nav-item-chevron-color": theme.palette.neutral[400],
            "--nav-scrollbar-color": theme.palette.neutral[900],
            "--nav-collapse-hover": theme.palette.neutral[100],
          };
        }

      case "evident":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-border-color": "transparent",
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": theme.palette.primary.main,
            "--nav-item-active-bg": theme.palette.primary.main,
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.common.white,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
            "--nav-collapse-hover": theme.palette.primary.main,
          };
        } else {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-border-color": "transparent",
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": theme.palette.primary.main,
            "--nav-item-active-bg": theme.palette.primary.main,
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.common.white,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
            "--nav-collapse-hover": theme.palette.primary.main,
          };
        }

      default:
        return {};
    }
  }, [theme, color]);
};

interface SideNavProps {
  color?: NavColor;
  sections?: Section[];
  open?: boolean;
  setOpen?: (value: boolean) => void;
}

const LogoutButton = ({ onClick }: { onClick: () => void }) => (
  <Stack
    spacing={2}
    sx={{
      flexGrow: 1,
      px: 2,
      py: 1,
    }}
  >
    <ButtonBase
      onClick={onClick}
      sx={{
        alignItems: "center",
        borderRadius: 1,
        display: "flex",
        justifyContent: "flex-start",
        px: 2,
        py: 1,
        textAlign: "left",
        width: "100%",
        "&:hover": {
          backgroundColor: "var(--nav-item-hover-bg)",
        },
      }}
    >
      <Box
        component="span"
        sx={{
          alignItems: "center",
          color: "var(--nav-item-icon-color)",
          display: "inline-flex",
          justifyContent: "center",
          mr: 2,
        }}
      >
        <Logout />
      </Box>
      <Box
        component="span"
        sx={{
          color: "error.light",
          flexGrow: 1,
          fontFamily: (theme) => theme.typography.fontFamily,
          fontSize: 14,
          fontWeight: 600,
          lineHeight: "24px",
          whiteSpace: "nowrap",
        }}
      >
        Log out
      </Box>
    </ButtonBase>
  </Stack>
);

export const SideNav: FC<SideNavProps> = (props) => {
  const { color = "evident", sections = [], open, setOpen } = props;
  const pathname = usePathname();
  const router = useRouter();
  const cssVars = useCssVars(color);
  const riskBannerHeight = useRiskBannerHeight();

  const sideNavWidth = (open && SIDE_NAV_WIDTH) || SIDE_NAV_WIDTH_CLOSED;

  return (
    <Box component="div">
      <IconButton
        data-cy="sidebar-close-button"
        onClick={() => setOpen && setOpen(!open)}
        size="small"
        sx={{
          ...cssVars,
          position: "fixed",
          top: `${riskBannerHeight + 80}px`,
          left: sideNavWidth - 12 + "px",
          height: "24px",
          width: "24px",
          color: "var(--nav-item-chevron-color)",
          backgroundColor: "var(--nav-bg)",
          "&:hover": {
            backgroundColor: "var(--nav-collapse-hover)",
          },
          border: "1px var(--nav-border-color) solid",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        {open && <ChevronLeftIcon sx={{ fontSize: "19px", pr: "1px" }} />}
        {!open && <ChevronRightIcon sx={{ fontSize: "18px" }} />}
      </IconButton>
      <Drawer
        anchor="left"
        PaperProps={{
          sx: {
            ...cssVars,
            backgroundColor: "var(--nav-bg)",
            borderRightColor: "var(--nav-border-color)",
            borderRightStyle: "solid",
            borderRightWidth: 1,
            paddingTop: `${riskBannerHeight}px`,
            color: "var(--nav-color)",
            width: sideNavWidth,
          },
        }}
        open={true}
        variant="persistent"
      >
        <Scrollbar
          id="side-nav-menu"
          sx={{
            height: "100%",
            "& .simplebar-content": {
              height: "100%",
            },
            "& .simplebar-scrollbar:before": {
              background: "var(--nav-scrollbar-color)",
            },
            ...(open
              ? {}
              : {
                  "& .simplebar-track.simplebar-horizontal": {
                    display: "none",
                  },
                }),
          }}
        >
          <Stack
            data-cy="sidebar"
            visibility={open ? "visible" : "collapse"}
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <Box component="div" sx={{ pt: 3, pb: 2, px: 2 }}>
              <img
                data-cy="sidebar-archax-logo"
                src="/assets/archax-web-logo.svg"
                alt="archax-logo"
              />
            </Box>
            <Stack
              component="nav"
              spacing={2}
              sx={{
                flexGrow: 1,
                px: 2,
              }}
            >
              {sections.map((section, index) => (
                <SideNavSection
                  items={section.items}
                  key={index}
                  pathname={pathname}
                  subheader={section.subheader}
                />
              ))}
            </Stack>
            <Box>
              <Stack
                spacing={2}
                sx={{
                  flexGrow: 1,
                  p: 2,
                }}
              >
                {footerSection.map((section, index) => (
                  <SideNavSection
                    items={section.items}
                    key={index}
                    pathname={pathname}
                    subheader={section.subheader}
                  />
                ))}
              </Stack>
              <Divider />
              <LogoutButton
                data-cy="logout-button"
                onClick={() => {
                  router.push(paths.logout);
                }}
              />
            </Box>
          </Stack>
        </Scrollbar>
      </Drawer>
    </Box>
  );
};

SideNav.propTypes = {
  color: PropTypes.oneOf<NavColor>(["blend-in", "discrete", "evident"]),
  sections: PropTypes.array,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
