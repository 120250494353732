import { SvgIcon } from "@mui/material";
import type { FC } from "react";

export const Custody: FC = () => {
  return (
    <SvgIcon data-cy="custody-drawer-icon">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.12"
          d="M2 7.93333C2 6.90657 2 6.39319 2.27248 6.00102C2.51217 5.65605 2.89462 5.37559 3.36502 5.19982C3.8998 5 4.59987 5 6 5H13C14.4001 5 15.1002 5 15.635 5.19982C16.1054 5.37559 16.4878 5.65605 16.7275 6.00102C17 6.39319 17 6.90657 17 7.93333V13.0667C17 14.0934 17 14.6068 16.7275 14.999C16.4878 15.3439 16.1054 15.6244 15.635 15.8002C15.1002 16 14.4001 16 13 16H6C4.59987 16 3.8998 16 3.36502 15.8002C2.89462 15.6244 2.51217 15.3439 2.27248 14.999C2 14.6068 2 14.0934 2 13.0667V7.93333Z"
          fill="currentColor"
          fillOpacity="1"
        />
        <path
          d="M16.5093 4.65625H15.8438V3.99073C15.8438 2.89303 14.9507 2 13.8531 2H3.49219C2.3937 2 1.5 2.8937 1.5 3.99219V14.6172C1.5 15.7157 2.3937 16.6094 3.49219 16.6094H16.5093C17.607 16.6094 18.5 15.7163 18.5 14.6187V6.64698C18.5 5.54928 17.607 4.65625 16.5093 4.65625ZM3.49219 3.32812H13.8531C14.2184 3.32812 14.5156 3.62536 14.5156 3.99073V4.65625H3.49219C3.12602 4.65625 2.82812 4.35835 2.82812 3.99219C2.82812 3.62602 3.12602 3.32812 3.49219 3.32812ZM17.1719 11.9609H14.5156C13.7833 11.9609 13.1875 11.3651 13.1875 10.6328C13.1875 9.90048 13.7833 9.30469 14.5156 9.30469H17.1719V11.9609ZM17.1719 7.97656H14.5156C13.051 7.97656 11.8594 9.16816 11.8594 10.6328C11.8594 12.0975 13.051 13.2891 14.5156 13.2891H17.1719V14.6187C17.1719 14.984 16.8746 15.2812 16.5093 15.2812H3.49219C3.12602 15.2812 2.82812 14.9834 2.82812 14.6172V5.87016C3.03598 5.94387 3.2594 5.98438 3.49219 5.98438H16.5093C16.8746 5.98438 17.1719 6.28161 17.1719 6.64694V7.97656Z"
          fill="currentColor"
          fillOpacity="1"
        />
      </svg>
    </SvgIcon>
  );
};
