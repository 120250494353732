import React, { createContext, useState, ReactNode, useContext } from "react";
import { Dialog } from "@mui/material";

interface DialogContext {
  openWithContent: any;
  closeModal: () => void;
}

export const DialogContext = createContext<DialogContext>({} as any);

type DialogProviderProps = {
  children: ReactNode;
};

export const DialogProvider = ({ children }: DialogProviderProps) => {
  const [content, setContent] = useState(null);
  const [open, setOpen] = useState(false);
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const openWithContent = (content: any) => {
    setContent(content);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setContent(null);
  };

  return (
    <DialogContext.Provider value={{ openWithContent, closeModal }}>
      {content && (
        <Dialog
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                minWidth: "fit-content",
              },
            },
          }}
        >
          {content}
        </Dialog>
      )}
      {children}
    </DialogContext.Provider>
  );
};

export const useDialogContext = () => useContext<DialogContext>(DialogContext);
