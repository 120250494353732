import React from "react";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { LicenseManager } from "ag-grid-enterprise";
import { getEnv } from "@ace/env";
import { ColDef } from "ag-grid-community";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Theme, useTheme } from "@mui/material";

const agGridLicense = getEnv("VITE_ACE_WEB_AGGRID_LICENSE");
LicenseManager.setLicenseKey(agGridLicense);

/*
  For additional overrides/alterations and full list of
  variables see: https://www.ag-grid.com/react-data-grid/global-style-customisation-variables/
**/
const agGridQuartzOverrides = (theme: Theme) =>
  ({
    height: "100%",
    width: "100%",
    paddingTop: "5px",
    "--ag-background-color": theme.palette.background.paper,
    "--ag-header-background-color": theme.palette.background.paperTertiary,
    "--ag-font-family": theme.typography.fontFamily,
  } as React.CSSProperties);

export type GridProps = AgGridReactProps & object;

const defaultColDefs: ColDef = {
  suppressHeaderMenuButton: true,
};

export default (props: GridProps) => {
  const theme = useTheme();
  return (
    <div
      style={agGridQuartzOverrides(theme)} // Add type assertion here
      className={
        theme.palette.mode === "light"
          ? "ag-theme-quartz"
          : "ag-theme-quartz-dark"
      }
    >
      <AgGridReact
        suppressCsvExport={true}
        onDisplayedColumnsChanged={(event) => event.api.sizeColumnsToFit()}
        onGridSizeChanged={(event) => event.api.sizeColumnsToFit()}
        onToolPanelVisibleChanged={(event) => event.api.sizeColumnsToFit()}
        defaultColDef={defaultColDefs}
        {...props}
      />
    </div>
  );
};
