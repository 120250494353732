import type { FC } from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const SimplifiedView: FC = () => {
  return (
    <SvgIcon data-cy="simplified-view-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clipPath="url(#clip0_4280_677)">
          <path
            opacity="0.12"
            d="M10.0001 18.3334C14.6025 18.3334 18.3334 14.6025 18.3334 10.0001C18.3334 5.39771 14.6025 1.66675 10.0001 1.66675C5.39771 1.66675 1.66675 5.39771 1.66675 10.0001C1.66675 14.6025 5.39771 18.3334 10.0001 18.3334Z"
            fill="currentColor"
          />
          <path
            d="M7.91675 6.25008H11.4584C12.4939 6.25008 13.3334 7.08955 13.3334 8.12508C13.3334 9.16062 12.4939 10.0001 11.4584 10.0001H7.91675H11.8751C12.9106 10.0001 13.7501 10.8395 13.7501 11.8751C13.7501 12.9106 12.9106 13.7501 11.8751 13.7501H7.91675M7.91675 6.25008H6.66675M7.91675 6.25008V13.7501M7.91675 13.7501H6.66675M8.33342 5.00008V6.25008M8.33342 13.7501V15.0001M10.8334 5.00008V6.25008M10.8334 13.7501V15.0001M18.3334 10.0001C18.3334 14.6025 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6025 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6025 1.66675 18.3334 5.39771 18.3334 10.0001Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4280_677">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
