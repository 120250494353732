// TODO: Instrument is deprecated as of api v2, replace with Asset and/or TradingPair

export interface Instrument {
  currency: string;
  decimals: number;
  description: string;
  id: number;
  marketStatus: string;
  name: string;
  priceDecimalPlaces: number;
  quantityDecimalPlaces: number;
  regulatedStatus: string;
  symbol: string;
  tickSize: string;
  tradingStatus: string;
  type: string;
  venueId: string;
}

export interface TradeHistoryEntry {
  amount: string;
  created: string;
  price: string;
  side: string;
  tradeRef: string;
}

export interface MarketDepth {
  buy: { [key: string]: number };
  indicativePrice: string;
  indicativeVolume: string;
  sell: { [key: string]: number };
  tradingPairId: number;
}

export interface MarketQuote {
  askPrice: string;
  askQty: string;
  bidPrice: string;
  bidQty: string;
  changePercent: string;
  changePrice: string;
  closePrice: string;
  cumulativeUnits: string;
  cumulativeValue: string;
  highPrice: string;
  lastPrice: string;
  lastQty: string;
  lowPrice: string;
  openPrice: string;
  sevenDayChange: string;
  sevenDayChangePercentage: string;
  sevenDayClosePrice: string;
  tradingPairId: number;
}

export interface Transaction {
  id: string;
  accountId: number;
  amount: string;
  commissionReference: string;
  referenceId: string;
  taxReference: string;
  ledgerType: string;
  organisationId: number;
  reportType: string;
  userId: number;
  created: string;
  orderReference: string | null;
  assetId: number | string;
}

// This is a slightly more generic name for a "deposit" or "withdrawal" that comes over the websocket.
// Have the interface aliased just for readability.
export interface LedgerEntry {
  accountId: string;
  amount: string;
  assetId: number;
  created: string;
  id: string;
  organisationId: number;
  reason: string;
  referenceId: string;
  status: string;
  type: string;
  updated: string;
  userId: number;
  walletAddress: string;
}
export type Withdrawal = LedgerEntry;
export type Deposit = LedgerEntry;

export interface TradingPair {
  baseAsset: string;
  baseAssetId: number;
  description: string;
  id: number;
  marketStatus: string;
  priceDecimalPlaces: number;
  productId: number;
  quantityDecimalPlaces: number;
  quoteAsset: string;
  quoteAssetId: number;
  regulatedStatus: string;
  tickSize: string;
  ticker: string;
  tradingStatus: string;
}

export interface Asset {
  decimalValue: number;
  id: number;
  name: string;
  status: string;
  symbol: string;
  type: string;
}

export interface Account {
  accountId: number;
  accountType: string;
  available: string;
  assetId: number;
  productId: number;
  organisationId: number;
  regulatoryEligibility: string;
  total: string;
}

// Returned by getSelectedAsset method in withAsset HOC
export interface SelectedAsset {
  assetId: string;
  type: "currency" | "security" | "crypto" | "ALL";
  value: string;
}

export interface AceJwt {
  exp: number;
  iat: number;
  primaryOrg: number;
  permissions: [[number, string[]]];
  sub: number;
  roles: string; // Deprecated: do not use this
}

export interface User {
  isAceAdmin: boolean;
  model: any;
  email: string;
  forcedLogout: false;
  forename: string;
  isDisconnected: boolean;
  name: string;
  permissions: Array<[number, Array<string>]>;
  primaryOrganisationId: string;
  token: string;
  userId: number;
}

export enum AceConnectionStatus {
  INITIALISING = "initialising",
  AUTHENTICATING = "authenticating",
  CONNECTED = "connected",
  RECONNECTING = "reconnecting",
  FAILED = "failed",
}

export interface AceIncomingSocketMessage {
  action: string;
  data: any;
  service: string;
  organisationId?: number;
  responseType?: string;
  timestamp?: string;
  status: string;
  reason?: string;
  token?: string;
}

export interface Execution {
  commission: string;
  created: string;
  grossExecutionAmount: string;
  id: string;
  orderId: string;
  price: string;
  quantity: string;
  referenceId: string;
  tax: string;
}

export interface OrderSubmitMessage {
  action: string;
  data: {
    tradingPairId: number;
    quantity?: string;
    orderType: 0 | 1;
    organisationId?: number;
    side: 0 | 1;
    limitPrice?: string;
    targetValue?: string;
  };
}

export interface Order {
  averagePrice: string;
  commission: string;
  created: string;
  currency: string;
  decimals: number;
  executions: Record<string, Execution>;
  filledPercent: string;
  filledQty: string;
  filledStatus: string;
  id: string;
  lastUpdated: string;
  limitPrice: string;
  orderStatus: string;
  organisationId: number;
  targetMarketValue: string | null;
  quantity: string;
  side: string;
  tax: string;
  tradingPairId: number;
  type: string;
  userId: number;
  userReference: string;
}

// We have this here because we have a grid in this web-lib that relies on a styles (and types) in our template we are extnding beyond MUI.
// The solution would be to only customise functionality in web-lib and leave the styling MUI references in ace-web
declare module "@mui/material/styles" {
  interface TypeBackground {
    paper: string;
    default: string;
    paperTertiary: string;
  }
}

export interface Document {
  id: number;
  description: string;
  category: string;
  key: string;
  label: string;
  filename: string;
  created: string;
  updated: string;
}

export interface Address {
  address1: string;
  address2: string;
  address3: string;
  postalCode: string;
  country: string;
}

export interface ClientCategorisation {
  description: string;
  clientCategory: string;
}

export enum ExamRating {
  PASS = "pass",
  FAIL = "fail",
}

export interface ExamClassification {
  attempts: number;
  examRating: ExamRating;
  productId: string;
  timestamp: string;
}
export interface ProfileData {
  country: string;
  email: string;
  forename: string;
  surname: string;
  created: string;
  finPromEligible: boolean;
  clientCategorisation: ClientCategorisation;
  examClassification: { [examClassificationId: string]: ExamClassification };
}

export interface KycData {
  classification: string;
  emailUpdates: boolean;
  investOptIn: false;
  kycStatus: string;
  pDocumentUpload: {
    documentMeta: Array<{
      documentType: string;
      fileName: string;
      originalFilename: string;
      idType: string;
    }>;
  };
  pEmploymentInfo: {
    annualRemunerationRange: string;
    industry: string;
    jobTitleAndPosition: string;
    natureOfEmployment: string;
  };
  pFurtherInfo: {
    taxDomicile: string;
    mainSourceOfWealth: string;
    mainSourceOfFunds: string;
    estimatedCashBalance: string;
    expectedTradingVolume: string;
    expectedTradingFrequency: string;
  };
  pPersonalDetails: any;
  pQuantitativeClass1: any;
  type: string;
}
