import { useEffect, useState } from "react";

export const useRiskBannerHeight = () => {
  const [riskBannerHeight, setRiskBannerHeight] = useState(0);

  useEffect(() => {
    const riskHeader = document.querySelector("div[data-cy='risk-header']");
    if (riskHeader) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setRiskBannerHeight(entry.contentRect.height);
        }
      });

      resizeObserver.observe(riskHeader);

      return () => {
        resizeObserver.unobserve(riskHeader);
      };
    }
  }, []);

  return riskBannerHeight;
};
