import { getEnv } from "@ace/env";

export const loadRecaptcha = () => {
  const clientRecaptchaKey = getEnv("VITE_ACE_WEB_RECAPTCHA_KEY");
  if (clientRecaptchaKey) {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${clientRecaptchaKey}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  } else {
    console.error("VITE_ACE_WEB_RECAPTCHA_KEY is not defined in window._env_");
  }
};
