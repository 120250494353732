import { Alert, AlertProps, Typography } from "@mui/material";
import posthog from "posthog-js";
import { useEffect } from "react";

interface ToastProps {
  title: string;
  content?: string;
  onClick?: () => void;
  type: "success" | "error" | "info" | "warning";
  "data-cy"?: string;
  sx?: AlertProps["sx"];
}

export const Toast = (props: ToastProps): JSX.Element => {
  useEffect(() => {
    // we don't send the props.content as that can have reference and order id which are identifiable.

    posthog.capture(`Toast:${props.type} - ${props.title}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Alert
      severity={props.type}
      onClick={props.onClick}
      data-cy={props["data-cy"]}
      sx={{ width: "320px", ...props.sx }}
    >
      <Typography>{props.title}</Typography>
      <Typography variant="body2">{props.content}</Typography>
    </Alert>
  );
};
