import { AceJwt } from "./types/ace-types";

export const parseJwt = (jwt: string) => {
  if (jwt) {
    return JSON.parse(window.atob(jwt.split(".")[1]));
  } else {
    console.error("Parse JWT was called with a empty/falsey string");
    return {};
  }
};

/**
 * Check if an acl role is present on the jwt for the user primary org.
 */
export const hasRole = (requiredRole: string, jwt: string): boolean => {
  const parsedJwt = parseJwt(jwt) as AceJwt;
  const { permissions, primaryOrg } = parsedJwt;
  const rolesOrgPair = permissions.find(
    (orgPerms) => orgPerms[0] === primaryOrg,
  );

  if (!rolesOrgPair) {
    return false;
  } else {
    return rolesOrgPair[1].includes(requiredRole);
  }
};

export const getUserPermissions = (jwt: string) => {
  const parsedJwt = parseJwt(jwt) as AceJwt;
  return parsedJwt.permissions;
};

export const getExpiration = (jwt: string): number => {
  const parsedJwt = parseJwt(jwt) as AceJwt;
  return parsedJwt.exp;
};

/**
 * Checks if a token expires within a specified number of minutes.
 *
 * @param token - The token to check.
 * @param minutes - The number of minutes to check against.
 * @returns A boolean indicating whether the token expires within the specified number of minutes.
 */
export const expiresWithInXMinutes = (token: string, minutes: number) => {
  const exp = getExpiration(token);
  const expirationTime = exp * 1000;
  const currentTime = new Date().getTime();
  const expirationThreshold = minutes * 60 * 1000;
  return expirationTime - currentTime <= expirationThreshold;
};

export const isTokenExpired = (token: string) => {
  const exp = getExpiration(token);
  const renewalTime = exp * 1000;
  const systemTime = new Date().getTime();
  return systemTime > renewalTime;
};

export const hasOneOfRole = (roles: string[], jwt: string): boolean => {
  const parsedJwt = parseJwt(jwt) as AceJwt;
  const { permissions, primaryOrg } = parsedJwt;
  const rolesOrgPair = permissions.find(
    (orgPerms) => orgPerms[0] === primaryOrg,
  );

  if (!rolesOrgPair) {
    return false;
  } else {
    for (const role of roles) {
      if (rolesOrgPair[1].includes(role)) {
        return true;
      }
    }
    return false;
  }
};

/**
 * Checks if the user has the roles, checks across all of the user's organisations
 * @param roles role to be checked against the user permissions
 * @param jwt
 * @returns boolean
 */
export const hasOneOfRolesInAnyOrg = (
  roles: string[],
  jwt: string,
): boolean => {
  const parsedJwt = parseJwt(jwt) as AceJwt;
  const { permissions } = parsedJwt;
  const userPermissions = permissions.reduce((a: Set<string>, b: any[]) => {
    b[1].forEach((role: string) => a.add(role));
    return a;
  }, new Set());
  return roles.some((role) => userPermissions.has(role));
};

export const getPrimaryOrgRoles = (jwt: string): string[] | undefined => {
  const parsedJwt = parseJwt(jwt) as AceJwt;
  const { permissions, primaryOrg } = parsedJwt;
  if (!permissions) return [];
  const rolesOrgPair = permissions.find(
    (orgPerms) => orgPerms[0] === primaryOrg,
  );

  return rolesOrgPair?.[1];
};

/**
 * Get user id from jwt
 */
export const getUserId = (jwt: string): number => {
  const parsedJwt = parseJwt(jwt) as AceJwt;
  return parsedJwt.sub;
};

/**
 * Get user org-id from jwt
 */
export const getUserOrgId = (jwt: string): number => {
  const parsedJwt = parseJwt(jwt) as AceJwt;
  return parsedJwt.primaryOrg;
};

/**
 * Checks if the user only has the roles, checks across all of the user's organisations
 * @param roles role to be checked against the user permissions
 * @param jwt
 * @returns boolean
 */
export const hasTheseRolesOnlyInAnyOrg = (
  roles: string[],
  jwt: string,
): boolean => {
  const parsedJwt = parseJwt(jwt) as AceJwt;
  const { permissions } = parsedJwt;
  const userPermissions = permissions.reduce((a: Set<string>, b: any[]) => {
    b[1].forEach((role: string) => a.add(role));
    return a;
  }, new Set());
  return (
    roles.every((role) => userPermissions.has(role)) &&
    roles.length === userPermissions.size
  );
};
