import type { FC } from "react";
import { SvgIcon } from "@mui/material";

export const Logout: FC = () => {
  return (
    <SvgIcon data-cy="logout-drawer-icon">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66667 13.6667V15.3333C2.66667 17.175 4.15917 18.6667 6 18.6667H14.3333C16.175 18.6667 17.6667 17.175 17.6667 15.3333C17.6667 12.5592 17.6667 8.10833 17.6667 5.33333C17.6667 3.4925 16.175 2 14.3333 2C11.9508 2 8.38333 2 6 2C4.15917 2 2.66667 3.4925 2.66667 5.33333V7C2.66667 7.46 3.04 7.83333 3.5 7.83333C3.96 7.83333 4.33333 7.46 4.33333 7C4.33333 7 4.33333 6.21917 4.33333 5.33333C4.33333 4.41333 5.08 3.66667 6 3.66667H14.3333C15.2542 3.66667 16 4.41333 16 5.33333V15.3333C16 16.2542 15.2542 17 14.3333 17C11.9508 17 8.38333 17 6 17C5.08 17 4.33333 16.2542 4.33333 15.3333C4.33333 14.4483 4.33333 13.6667 4.33333 13.6667C4.33333 13.2075 3.96 12.8333 3.5 12.8333C3.04 12.8333 2.66667 13.2075 2.66667 13.6667ZM8.98833 9.5L7.91083 8.42333C7.58583 8.09749 7.58583 7.57 7.91083 7.24417C8.23667 6.91917 8.76417 6.91917 9.08917 7.24417L11.5892 9.74417C11.915 10.07 11.915 10.5975 11.5892 10.9233L9.08917 13.4233C8.76417 13.7483 8.23667 13.7483 7.91083 13.4233C7.58583 13.0975 7.58583 12.57 7.91083 12.2442L8.98833 11.1667H1.83333C1.37333 11.1667 1 10.7933 1 10.3333C1 9.87417 1.37333 9.5 1.83333 9.5H8.98833Z"
          fill="currentColor"
          fillOpacity="1"
        />
      </svg>
    </SvgIcon>
  );
};
