import { getEnv } from "@ace/env";
import { Document } from "@archax/web-lib";

export const getDocuments = async (token: string): Promise<Document[]> => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const response = await fetch(
    `${getEnv("VITE_ACE_WEB_AUTH_SERVER")}/documents`,
    {
      method: "GET",
      headers,
    },
  );
  const json = await response.json();

  if (json.status === "ERROR") {
    throw new Error(json.error[0].msg);
  }

  const data = json.data as Document[];

  return data;
};
