import { Suspense, lazy } from "react";
// import { GridProps } from "./grid";

const GridDeferred = lazy(() => import("./grid"));

export const Grid = (props: any) => (
  <Suspense fallback={<div>Loading Grid...</div>}>
    <GridDeferred {...props} />
  </Suspense>
);
