import {
  Alert,
  AlertTitle,
  Box,
  Card,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { format, addDays, isPast } from "date-fns";
import { useDialogContext } from "../../ace-widgets/dialog-provider";
import Close from "@mui/icons-material/Close";
import { KYC_STATUS } from "../../constants";
import { isInFailedKycStatus } from "../../rules";

interface CoolOffBannerProps {
  kycStatus: KYC_STATUS;
  profileData: any;
  countdownStartTimestamp?: string;
}

const FinPromModal = ({ handleClose }: { handleClose: () => void }) => (
  <Box
    data-cy="cool-off-banner-learn-more-modal"
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    <Card>
      <Box
        p={3}
        sx={{
          maxWidth: 542,
        }}
      >
        <Stack spacing={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5">FCA financial promotion rules</Typography>
            <IconButton
              data-cy="cool-off-banner-learn-more-modal-close-button"
              onClick={handleClose}
            >
              <Close />
            </IconButton>
          </Stack>
          <Divider />
          <Box>
            Archax ensures that all new clients experience an initial 24-hour
            cooling-off period before an investment can be made. This allows
            time to reflect on whether the service is appropriate for you and to
            determine whether you wish to proceed.
          </Box>
        </Stack>
      </Box>
    </Card>
  </Box>
);

export const CoolOffBanner = (props: CoolOffBannerProps) => {
  const { openWithContent, closeModal } = useDialogContext();

  const handleLearnMore = () => {
    openWithContent(<FinPromModal handleClose={closeModal} />);
  };

  if (
    !props.profileData ||
    (props.profileData && props.profileData.country !== "United Kingdom") ||
    isInFailedKycStatus(props.kycStatus)
  ) {
    return;
  }

  if (!props.countdownStartTimestamp) return;

  const created = new Date(props.countdownStartTimestamp);
  const expirationDate = addDays(created, 1);

  if (isPast(expirationDate)) return;

  return (
    <Alert severity="info" data-cy="cool-off-banner">
      <AlertTitle color="white">24-hour cooling-off period</AlertTitle>
      {`You are within your 24-hour cooling-off period which will end ${format(
        expirationDate,
        "MMMM dd yyyy HH:mm:ss",
      )}. You won’t be able to deposit or trade during this period. `}
      <Link
        onClick={handleLearnMore}
        sx={{ cursor: "pointer" }}
        data-cy="cool-off-banner-link"
      >
        Click this link to learn more.
      </Link>
    </Alert>
  );
};
