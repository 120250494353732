import { AceConnectionStatus, ArchaxLogo, useAce } from "@archax/web-lib";
import "./reconnection-overlay.css";
import React from "react";

const ReconnectionOverlay = ({ children }: { children: React.ReactNode }) => {
  const { connectionStatus } = useAce();

  return (
    <>
      {connectionStatus === AceConnectionStatus.RECONNECTING && (
        <div className="reconnection-overlay">
          <div
            style={{
              position: "absolute",
              zIndex: 1201,
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgb(10, 8, 31)",
              opacity: 0.8,
            }}
          />
          <div
            className="loader"
            style={{
              zIndex: 1202,
              borderRadius: 24,
              width: 106,
              height: 106,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              padding: 20,
              background:
                "linear-gradient(132deg, rgba(255, 255, 255, 0.40) -8.67%, rgba(255, 255, 255, 0.10) 53.98%)",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <span />
            <span />
            <ArchaxLogo
              aria-label="Reconnecting"
              width={"72px"}
              height={"57px"}
            />
          </div>
        </div>
      )}
      {children}
    </>
  );
};

export { ReconnectionOverlay };
