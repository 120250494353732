import { Stack, Typography } from "@mui/material";
import { ErrorBoundary as EB } from "react-error-boundary";

function fallbackRender() {
  return (
    <Stack p={1}>
      <Typography color="error">Error occured:</Typography>
      <p>
        Please contact support at{" "}
        <a href="mailto:support@archax.com">support@archax.com</a> if error
        persists...
      </p>
    </Stack>
  );
}

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  return <EB fallbackRender={fallbackRender}>{children}</EB>;
};

const errorBoundary = (component: any) => {
  return (
    <EB key={component.type.name} fallbackRender={fallbackRender}>
      {component}
    </EB>
  );
};

export { ErrorBoundary, errorBoundary };
