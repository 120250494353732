/**
 * Onboarding has a lot of conditions and business logic.
 * The main value here isn't code dedup - it's the very clear and distinct names given to these groups of conditions that refer to some concept our business supports.
 * We should make sure to keep update-to-date, not just the conditions, but the NAMES depending how business logic / rules shift.
 *
 * If something doesn't seem correctly named based on usage or business context - raise it.
 */

import { KYC_STATUS } from "./constants";

const isInFailedKycStatus = (kycStatus: KYC_STATUS): boolean => {
  return [
    KYC_STATUS.GENERAL_FAILED_SAR,
    KYC_STATUS.GENERAL_FAILED_TECHNICAL,
  ].includes(kycStatus);
};

const isInPersonalPendingStatus = (kycStatus: KYC_STATUS): boolean => {
  return [
    KYC_STATUS.PERSONAL_PERSONAL_DETAILS,
    KYC_STATUS.PERSONAL_EMPLOYMENT_INFO,
    KYC_STATUS.PERSONAL_FURTHER_INFO,
    KYC_STATUS.PERSONAL_DOCUMENT_UPLOAD,
  ].includes(kycStatus);
};

export { isInFailedKycStatus, isInPersonalPendingStatus };
