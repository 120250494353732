// Note: all function here should end with "FeatureFlag" so it's easy to identify any where they are used.
// All feature flags should be short term and removed once the feature is fully signed-off on and/or implemented.
// Current case is purely for internal business and PM sign-off. Who can be instructed how to enable/disable the features.

// Do not make dynamic - intended use is manual edit in localstorage, refresh the page, and see effect.

const isVitest =
  typeof process !== "undefined" && process.env.VITEST === "true";

const tradeSliderFeatureFlag =
  isVitest || localStorage.getItem("trade-slider") === "1";

const simplifiedViewFeatureFlag =
  isVitest || localStorage.getItem("simplified-trading") === "1";

export const featureFlags = {
  tradeSliderFeatureFlag,
  simplifiedViewFeatureFlag,
};
