export const LocalStorage = {
  /** returns parsed value of the item from localStorage */
  getItem: (key: string) => {
    const item = localStorage.getItem(key);

    // if item is not found, return null
    if (!item) return null;

    // if item starts with { or [, parse it as JSON
    if (item.startsWith("{") || item.startsWith("[")) {
      return JSON.parse(item);
    }

    return item;
  },
  /** stringify value and save it in localStorage */
  setItem: (key: string, value: any) => {
    let stringValue = value;

    // stringify if value is not a string
    if (typeof value !== "string") {
      stringValue = JSON.stringify(value);
    }

    localStorage.setItem(key, stringValue);
  },
};
