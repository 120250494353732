import { MarketDepth } from "./types/ace-types";

export const areOrderbooksCrossed = (data: any): number | void => {
  const marketDepthsBooks = Object.keys(data)
    .filter((key) => key.includes("market-depths"))
    .map((key) => data[key])
    .map((obj) => Object.values(obj)[0] as MarketDepth);

  for (const book of marketDepthsBooks) {
    const buyPrices = Object.keys(book.buy).map((price) => parseInt(price));
    const sellPrices = Object.keys(book.sell).map((price) => parseInt(price));
    const highestBuyPrice = Math.max(...buyPrices);
    const lowestSellPrice = Math.min(...sellPrices);
    if (highestBuyPrice > lowestSellPrice) {
      return book.tradingPairId;
    }
  }
};
