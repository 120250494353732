import type { FC } from "react";
import { SvgIcon } from "@mui/material";

export const Help: FC = () => {
  return (
    <SvgIcon data-cy="help-drawer-icon">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          opacity="0.12"
          cx="10.5"
          cy="10.5"
          r="7.5"
          fill="currentColor"
          fillOpacity="1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 17.2C13.9765 17.2 17.2 13.9765 17.2 10C17.2 6.02355 13.9765 2.8 10 2.8C6.02355 2.8 2.8 6.02355 2.8 10C2.8 13.9765 6.02355 17.2 10 17.2ZM10 19C14.9705 19 19 14.9705 19 10C19 5.02943 14.9705 1 10 1C5.02943 1 1 5.02943 1 10C1 14.9705 5.02943 19 10 19Z"
          fill="currentColor"
          fillOpacity="1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.2197 7.75066C8.97085 8.18091 8.42032 8.32789 7.99003 8.07905C7.55977 7.83015 7.41274 7.2796 7.66163 6.84934C8.12711 6.04466 8.99938 5.5 9.99991 5.5C11.491 5.5 12.6999 6.70884 12.6999 8.2C12.6998 9.18793 12.0938 9.9946 11.3499 10.45C10.7523 10.8158 11.1249 11.8 9.99748 11.8C9.5005 11.8 9.09748 11.3971 9.09748 10.9C9.09748 10.8684 9.0991 10.8372 9.10234 10.8064C9.12727 10.3305 9.33949 9.94312 9.57061 9.66151C9.81469 9.36415 10.1355 9.12133 10.3809 8.97544C11.7459 8.164 10.081 6.50386 9.2197 7.75066ZM9.99748 12.7C9.5005 12.7 9.09748 13.1029 9.09748 13.6C9.09748 14.0971 9.5005 14.5 9.99748 14.5C10.4945 14.5 10.8975 14.0971 10.8975 13.6C10.8975 13.1029 10.4945 12.7 9.99748 12.7Z"
          fill="currentColor"
          fillOpacity="1"
        />
      </svg>
    </SvgIcon>
  );
};
